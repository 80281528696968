import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {Card, Col, Radio, Row, Space} from 'antd';
import React, {useState} from 'react';
import {ListBase, ListProps, useListContext,} from 'react-admin';
import GridView from './GridView';
import ListView from './ListView';
import ServiceItemFilterPanel from "./SubComponents/ServiceItemFilterPanel";


const ServiceItemsListView = (props: ListProps) => {
    const { ids, data, loading, displayedFilters, filterValues, setFilters, page, total, perPage, setPage, setPerPage } = useListContext();
    const [viewMode, setViewMode] = useState<"grid"|"list">("grid");


    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Service Items"
            extra={
            <Radio.Group onChange={(e) => {setViewMode(e.target.value)}} buttonStyle="solid" defaultValue={viewMode}>
                <Radio.Button value={"grid"}><AppstoreOutlined /></Radio.Button>
                <Radio.Button value={"list"}><UnorderedListOutlined /></Radio.Button>
            </Radio.Group>
            }
        >
            <Row>
                <Col span={5}>
                <div style={{ height: '100%', borderRightColor: '#f0f0f0', borderRightWidth: '1px', borderRightStyle: 'solid', padding: '20px' }}>
                    <ServiceItemFilterPanel filterValues={filterValues} setFilters={setFilters} />
                </div>
                </Col>
                <Col style={{overflowX: 'auto'}} span={19}>
                {
                    viewMode==="list"?
                        <ListView />
                        :
                        <GridView />
                }
                </Col>
            </Row>

    </Card>
  )
}

const ServiceItemsList = (props: ListProps) => {
    return (
        <ListBase
            sort={{ field: 'id', order: 'DESC' }}
            filter={{light: true}}
            perPage={25}
            {...props}
        >
            <ServiceItemsListView />
        </ListBase>
    )
}

export default ServiceItemsList;
