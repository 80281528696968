import { Space, Typography } from 'antd';
import React from 'react';
import { BuilderWizardProvider } from './BuilderWizardProvider';
import SubmissionEditor from './SubmissionEditor/SubmissionEditor';

const BuilderWizard = () => {
  const { Title, Text } = Typography;

  return (
    <BuilderWizardProvider>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
        <Title style={{ justifyContent: 'left', display: 'flex' }} level={4}>Submission Builder</Title>
        <Space />
        <SubmissionEditor />
      </div>
    </BuilderWizardProvider>
  );
}

export default BuilderWizard;