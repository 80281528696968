import {Button, Card, Form, Input, Select, Typography, Switch} from "antd";
import React from "react";
import { Edit, useDataProvider, useEditContext, useNotify, useRedirect, usePermissions, useQuery } from 'react-admin';
import DeleteButton from "@src/components/Common/DeleteButton"
import {ExtendedDataProvider} from "../../types";
import TextArea from "antd/es/input/TextArea";


const { Title } = Typography;
const { Option } = Select;


const AIValidatorCreateForm = (props: any) => {
  const [form] = Form.useForm();
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const { record, loading, loaded} = useEditContext();

  const { loaded: servicesLoaded, error: servicesError, data: serviceData } = useQuery({
    type: 'getList',
    resource: 'services',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'asc' },
      filter: {}
    }
  });
  const { data: llmModelsData } = useQuery({
    type: 'getList',
    resource: 'llm_models',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'asc' },
      filter: {}
    }
  });

  const onSubmit = (values: any) => {
    if (values.headers){
      values['headers'] = Object.fromEntries(values.headers.map(
        (x: { header_key: string; header_value: string; }
        ) => [x.header_key, x.header_value]))
    }
    if (record) {
      if (typeof values.service == "object") {
        values.service = values.service.value
      }
      if (typeof values.llm_model == "object") {
        values.llm_model = values.llm_model.value
      }
      dataProvider.update('serviceowner_ai_validators', { id: record.id, data: values, previousData: { id: record.id } })
        .then(() => {
          notify('AI Validator updated successfully');
          redirect('/serviceowner_ai_validators')
        })
        .catch((error: any) => {
          if (error.status == 400) {
            for (const [key, value] of Object.entries<any>(error.body)) {
              form.setFields([
                { name: key, errors: value }
              ])
            }
          }
        })
    } else {
      dataProvider.create('serviceowner_ai_validators', {data: values})
        .then(() => {
          notify('AI Validator created successfully');
          redirect('/serviceowner_ai_validators')
        })
        .catch((error: any) => {
          if (error.status == 400) {
            for (const [key, value] of Object.entries<any>(error.body)) {
              form.setFields([
                {name: key, errors: value}
              ])
            }
          }
        })
    }
  }

  return (
    <Form
      form={form}
      name="create_ai_validator"
      labelCol={{ span: 6 }}
      className="row-col"
      style={{marginLeft: "10px"}}
      labelAlign="left"
      initialValues={record?
        {
          ...record,
          service: { value: record.service.id, label: record.service.name },
          llm_model: { value: record.llm_model.id, label: record.llm_model.name }
        }:
        { remember: true }}
      onFinish={onSubmit}
    >
      <Form.Item label="Service" name="service" rules={[{ required: true, message: 'Please select a service' }]}>
        <Select placeholder="Select Service" style={{ width: '100%' }} disabled={permissions !== "ADMIN"}>
          {
              serviceData &&
              serviceData.map((service: any) => <Option key={service.id} value={service.id}>{service.name}</Option>)
          }
        </Select>
      </Form.Item>

      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a Name' }]}>
        <Input placeholder="Name" disabled={permissions !== "ADMIN"}/>
      </Form.Item>

      <Form.Item label="LLM Model" name="llm_model" rules={[{ required: true, message: 'Please select a model' }]}>
        {
          llmModelsData &&
            <Select
                placeholder="Select a LLM model"
                style={{ width: '100%' }}
                disabled={permissions !== "ADMIN"}
                // defaultValue={record && record.llm_model.id}
            >
              {llmModelsData.map((llmModel: any) => <Option key={llmModel.id} value={llmModel.id}>{llmModel.name}</Option>)}
            </Select>
        }
      </Form.Item>

      <Form.Item label={"Prompt"} name={"prompt"} rules={[{ required: true }]}>
        <TextArea rows={10} placeholder="Prompt" maxLength={10000} />
      </Form.Item>

      <Form.Item label="Allow Auto Approval" name="allow_auto_approval">
        <Switch defaultChecked={record?.allow_auto_approval == true}/>
      </Form.Item>


      <Form.Item label="Allow Auto Rejection" name="allow_auto_rejection">
        <Switch defaultChecked={record?.allow_auto_rejection == true}/>
      </Form.Item>

      <Form.Item label="Send Service Info" name="send_service_info">
        <Switch defaultChecked={record?.send_service_info == true}/>
      </Form.Item>

      <Form.Item label="Send Existing Service Items" name="send_existing_service_items">
        <Switch defaultChecked={record?.send_existing_service_items == true}/>
      </Form.Item>

      <Form.Item>
        {
            permissions === "ADMIN" &&
            <Button style={{ width: "20%" }} type="primary" htmlType="submit" className="form-button">{record? "Update":"Create"}</Button>
        }
        {
            record &&
            permissions === "ADMIN" &&
            <DeleteButton title={"AI Validator"} resource={"serviceowner_ai_validators"} style={{ width: "20%", float: "right" }} record={record} />
        }
      </Form.Item>
    </Form>

  )
}

const AIValidatorCreate = (props: any) => {
  return (
    <>
      <Card className=" header-solid h-full ant-card pt-0" bordered={false}>
        <Title level={4}>AI Validator</Title>
        {props.id &&
            <Edit {...props}>
                <AIValidatorCreateForm />
            </Edit>
        }
        {!props.id && <AIValidatorCreateForm />}
      </Card>
    </>
  )
}

export default AIValidatorCreate;
