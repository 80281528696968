import {Card, Form, Input} from "antd";
import React from "react";

const PrivateLLMModelForm = (record: any) => {

    return (
        <>
            <Form.Item label="Type" name="model_type" initialValue={"OpenAI"} hidden={true}>
                <Input />
            </Form.Item>

            <Form.Item label={"Base URL"} name={"base_url"} rules={[{ required: true }]}>
                <Input placeholder={"URL"}/>
            </Form.Item>
        </>
    )
}

export default PrivateLLMModelForm;
