import { Alert, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import Editor, { Monaco } from '@monaco-editor/react';
import yaml from 'js-yaml';
import { editor } from 'monaco-editor';


const TeamMetadataModal = ({ modalVisible, setModalVisible, handleModalSubmit, metadata }: { modalVisible: boolean, setModalVisible: Function, handleModalSubmit: (metadata: object | null) => void, metadata: any }) => {

    const [isValidMetadata, setIsValidMetaData] = useState<boolean>(true)
    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const parsedMetadata = typeof metadata === 'object'  && Object.keys(metadata).length === 0 ? undefined : metadata

    const handleOk = () => {
        try {
            const teamMetadata = editorRef?.current?.getValue() ?? ''
             // If the parsed result is an empty string, convert it to an empty object
            const parsedData = teamMetadata.trim() === '' ? {} : yaml.load(teamMetadata) as object || {};
            handleModalSubmit(parsedData);
            setIsValidMetaData(true)
            setModalVisible(false)
        } catch (e) {
            console.error(e)
            setIsValidMetaData(false)
        }
    }

    const handleCancel = () => {
        setModalVisible(false)
    }

    const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor) => {
        editorRef.current = editor;
    }

    return (
        <>
            <Modal
                title={`Team Metadata`}
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {!isValidMetadata && <Alert message="YAML is invalid" type="error" />}
                <Editor
                    options={{
                        formatOnPaste: true,
                        minimap: {
                            enabled: false,
                        },
                    }}
                    height="50vh"
                    defaultLanguage="yaml"
                    value={(yaml.dump(parsedMetadata))}
                    onMount={handleEditorDidMount}
                />
            </Modal>
        </>
    )
}

export default TeamMetadataModal;