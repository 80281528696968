import { ArrayFieldTemplateProps, RJSFSchema, UiSchema } from "@rjsf/utils";
import React from "react";
import { Button, Collapse, Space, Form, Row, Col } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

// Helper function to determine if an array item is single-level
const isSingleLevelItem = (item: any) =>
  typeof item.schema === "boolean" || item.schema.type !== "object";

// Helper function to render nested objects with indentation
const renderNestedObject = (props: any, level: number = 0) => {
  const indentStyle = { paddingLeft: `${level * 20}px` };

  return (
    <div style={indentStyle}>
      {Object.keys(props.properties).map((propertyName) => (
        <Form.Item key={propertyName} label={propertyName}>
          {props.properties[propertyName].uiSchema["ui:widget"] ===
            "CustomArrayField" ? (
            <CustomArrayFieldTemplate
              {...props.properties[propertyName]}
              level={level + 1}
            />
          ) : (
            props.properties[propertyName].content
          )}
        </Form.Item>
      ))}
    </div>
  );
};

const CustomArrayFieldTemplate = (
  props: ArrayFieldTemplateProps & { level?: number }
) => {
  const { uiSchema } = props
  const level = props.level || 0;
  const headerStyle = { fontWeight: level > 0 ? "normal" : "bold" };
  const isSingleLevelArray = props.items && props.items.every(isSingleLevelItem);

  // Render function for single-level array items
  const renderSingleLevelItem = (element: any) => (
    <Form.Item style={{ marginBottom: "5px" }} key={element.key} labelAlign="left" label={element.name}>
      <Row>
        <Col flex="auto">
          {element.children}
        </Col>
        <Col>
          <Button
            danger
            type="primary"
            shape="circle"
            className={"submission-builder-del-btn"}
            style={{ marginLeft: '8px' }}
            icon={<DeleteOutlined />}
            onClick={element.onDropIndexClick(element.index)}
          />
        </Col>
      </Row>
    </Form.Item>
  );

  // Render function for nested array items
  const renderNestedItem = (element: any) => {
    return (
      <Collapse style={{ marginBottom: '10px'}} key={element.key} bordered={true} defaultActiveKey={[]}>
        <Panel
          key={element.key}
          header={
            <Row align="middle" style={{ width: '100%'}} gutter={[0, 8]}>
              <Col span={22}>
              <span style={headerStyle}>{element?.children?.props?.name}</span>
              </Col>
              <Col span={2}>
              <Button
                danger
                type="primary"
                shape="circle"
                className={"submission-builder-del-btn"}
                icon={<DeleteOutlined />}
                onClick={element.onDropIndexClick(element.index)}
              />
              </Col>
            </Row>
          }
        >
          {element.hasChildren ? renderNestedObject(element, level) : element.children}
        </Panel>
      </Collapse>
    )
  };

  return (
    <div style={{  textAlign: 'left'}}>
      {props.items &&
        props.items.map(isSingleLevelArray ? renderSingleLevelItem : renderNestedItem)}
      {props.canAdd && (
        <Button
          type="dashed"
          onClick={props.onAddClick}
          icon={<PlusCircleOutlined />}
          style={{ width:"100%" }}
        >Add {props?.title}</Button>
      )}
    </div>
  );
};

export default CustomArrayFieldTemplate;