import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useLogin, useNotify } from 'ra-core';
import React, { useState } from 'react';

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const onSubmit = (values: any) => {
        setLoading(true);
        login(values)
            .then(() => setLoading(false))
            .catch(error => {
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    'error',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    }
                );
                setLoading(false);
            });
    };

    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>

            <Form.Item>
                <Button loading={loading} style={{ width: "100%" }} type="primary" htmlType="submit" className="login-form-button">
                    Log in
                </Button>
            </Form.Item>
        </Form>
    )
}

export default LoginForm;