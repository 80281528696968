import { ListBase, ListProps, Record, useListContext, usePermissions } from "react-admin";
import React from "react";
import {Button, Card, Table} from "antd";
import {Link} from "react-router-dom";
import SkeletonTable, {SkeletonTableColumnsType} from "@src/components/Common/SkeletonTable";
import {EditOutlined} from "@ant-design/icons";

const LLMModelsListView = () => {
    const { ids, data:llmModels, loaded, loading, error } = useListContext();
    const { permissions } = usePermissions();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            render: (text: any, record: any) => (
                <>
                    <Button style={{ marginRight: '5px'}}>
                        <Link to={`/llm_models/${record.id}`}><EditOutlined />{permissions === "ADMIN"?"Edit":"Show"}</Link>
                    </Button>
                </>
            )
        },
    ];

    const tableData: Record[] = []
    ids.forEach(id => tableData.push(llmModels[id]));
    return (
        <>
            {
                (loaded || error) &&
                <Card
                    title={"LLM Models Settings"}
                    className="criclebox tablespace mb-24"
                    extra={<Link to="/llm_models/create"><Button type='primary'>Register a New LLM Model</Button></Link>}
                >
                    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
                        <Table
                            pagination={false}
                            rowKey="name"
                            columns={columns as SkeletonTableColumnsType[]}
                            dataSource={tableData}
                        />
                    </SkeletonTable>
                </Card>
            }
        </>
    )
}


const LLMModelsList = (props: ListProps) => (
    <ListBase {...props}>
        <LLMModelsListView />
    </ListBase>
)


export default LLMModelsList;
