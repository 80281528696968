import { Collapse, Space, Typography} from 'antd';
import React from 'react';
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const ValidationErrors = ({ errors }: { errors: any }) => {

    const extractErrors = (jsonResponse: any) => {
        const errors = [];

        // Iterate over the JSON structure
        for (const teamName in jsonResponse) {
            if(["metadata", "general"].includes(teamName)){
                errors.push(jsonResponse[teamName]);
                continue
            }

            const teamData = jsonResponse[teamName];
            for (const applicationName in teamData) {
                if (applicationName === "metadata")
                    for(const fieldName in teamData["metadata"])
                        errors.push(`Team Metadata - ${fieldName} - ${teamData["metadata"][fieldName]}`)

                else if (applicationName === "general")
                    errors.push(`Team - ${teamData["general"]}`)

                else {
                    const applicationData = teamData[applicationName];
                    for (const serviceName in applicationData) {
                        if(serviceName === "metadata")
                            for(const fieldName in applicationData["metadata"])
                                errors.push(`${applicationName} - metadata - ${fieldName} - ${applicationData["metadata"][fieldName]}`)

                        else if (serviceName === "general")
                            errors.push(`Team - ${teamData["general"]}`)

                        else {
                            const serviceData = applicationData[serviceName];

                            if ("state" in serviceData)
                                errors.push (`${applicationName} - ${serviceName} - ${serviceData["state"]}`)

                            else
                                for (const serviceItemName in serviceData) {
                                    const serviceItemData = serviceData[serviceItemName];
                                    for (const fieldName in serviceItemData)
                                        errors.push (`${applicationName} - ${serviceItemName} - ${serviceItemData[fieldName]}`);
                                }
                        }
                    }
                }
            }
        }

        return errors;
    }

    const generateList = (errors: any) => {
        const extractedErrors = extractErrors(errors)

        return (
            <Paragraph>
                <ul style={{textAlign:"left"}}>
                    {extractedErrors.map(err => <li>{err}</li>)}
                </ul>
            </Paragraph>
        )
    }
    return (
        <Space direction="vertical" style={{margin: "5px"}}>
            <Collapse bordered={false} style={{backgroundColor: "#fff2f0", borderColor:"#ffccc7", width: "500px", marginBottom:"5px"}}>
            <Panel header={<><Text>Validation Failed</Text></>} key="1">
                    {generateList(errors)}
                </Panel>
            </Collapse>
        </Space>
    );
};

export default ValidationErrors;
