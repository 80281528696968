import React, { createRef, useMemo } from 'react';
import { FieldTemplateProps } from "@rjsf/utils";
import { Form as AntForm, Col, Row, Typography } from 'antd';

const CustomFieldTemplateForArray = (props: FieldTemplateProps) => {

    const { label, id, required, children, description, uiSchema, schema, errors, rawHelp, rawErrors } = props;
    const isArrayWithItems = props?.children?.props?.children?.[0]?.props?.schema?.items

    if (isArrayWithItems) {
        return (
            <>
                <AntForm.Item label={`${label} ::`} labelCol={{ span: 24 }} />
                <AntForm.Item
                    htmlFor={id}
                    required={required}
                    wrapperCol={{ span: 24 }}
                    help={(!!rawHelp) || (rawErrors?.length ? errors : undefined)}
                    validateStatus={rawErrors?.length ? 'error' : undefined}
                >
                    {children}
                </AntForm.Item>
            </>
        )
    }

    return (
        <AntForm.Item
            labelAlign='left'
            label={label}
            //prevent showing parent duplicate data
            htmlFor={id}
            required={required}
            help={(!!rawHelp) || (rawErrors?.length ? errors : undefined)}
            validateStatus={rawErrors?.length ? 'error' : undefined}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
        >
            {children}
        </AntForm.Item>
    )
}

const CustomFieldTemplate = (props: FieldTemplateProps) => {
    const { label, id, required, children, description, uiSchema, schema, errors, rawHelp, rawErrors, registry } = props;
    const isArrayItem = /\_\d+$/.test(String(id));

    // Keep the array check and return early if it's an array
    if (schema.type === "array") {
        return <CustomFieldTemplateForArray {...props} />;
    }

    // Get the field name by removing the ID prefix (e.g., "129_")
    const fieldName = String(id).replace(/^\d+_/, '');

    // Remove array index from field name for property checking
    const baseFieldName = fieldName.replace(/_\d+$/, '');

    // Check if this is a nested field (but not an array item)
    const isNestedField = schema.type !== "object" &&
        registry.rootSchema.properties &&
        !Object.keys(registry.rootSchema.properties).includes(baseFieldName) &&
        !isArrayItem; // Don't indent array items

    // Debug logging
    // console.log('Field:', {
    //     id,
    //     fieldName,
    //     baseFieldName,
    //     rootProperties: Object.keys(registry.rootSchema.properties || {}),
    //     isNestedField,
    //     isArrayItem
    // });

    return (
        <AntForm.Item
            label={!uiSchema && props?.schema?.type != 'object' && !isArrayItem && label}
            htmlFor={id}
            required={required}
            help={(!!rawHelp) || (rawErrors?.length ? errors : undefined)}
            validateStatus={rawErrors?.length ? 'error' : undefined}
            labelAlign='left'
            labelCol={{ span: uiSchema || schema.type === "object" || isArrayItem ? 0 : 8 }}
            wrapperCol={{ span: uiSchema || schema.type === "object" || isArrayItem ? 24 : 16 }}
            style={{
                marginBottom: "5px",
                paddingLeft: isNestedField ? 16 : 0
            }}
        >
            <Row align='middle' gutter={[0, 8]}>
                <Col style={{ textAlign: 'left' }} span={24}>{children}</Col>
                {description && (
                    <Col span={24}>
                        <small style={{ color: "#888" }}>{description}</small>
                    </Col>
                )}
            </Row>
        </AntForm.Item>
    );
};

export default CustomFieldTemplate;