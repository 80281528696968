import {Form, Input} from "antd";
import React from "react";

const OpenAIModelForm = (record: any) => {

    return (
        <>
            <Form.Item label="Type" name="model_type" initialValue={"OpenAI"} hidden={true}>
                <Input />
            </Form.Item>

            <Form.Item label={"Api Key"} initialValue={record?.extra_data?.OPENAI_API_KEY} name={"OPENAI_API_KEY"} rules={[{ required: true }]}>
                <Input placeholder={"Api Key"}/>
            </Form.Item>

            <Form.Item label={"Assistant ID"} initialValue={record?.extra_data?.OPENAI_ASSISTANT_ID} name={"OPENAI_ASSISTANT_ID"} rules={[{ required: true }]}>
                <Input placeholder={"Assistant ID"}/>
            </Form.Item>

            {/*<Form.Item label={"System Instructions"} name={"instructions"}>*/}
            {/*    <TextArea rows={20} placeholder="instructions" maxLength={10000} />*/}
            {/*</Form.Item>*/}
        </>
    )
}

export default OpenAIModelForm;