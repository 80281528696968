import {Record, useDataProvider, useNotify, useRedirect} from "react-admin";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Button, Modal} from "antd";
import React from "react";

const { confirm } = Modal;


const DeleteButton = ({ title, resource, record, style = {} }: { title: string, resource: string, record: Record, style: any },) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const handleClick = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to delete the ${title}?`,
            onOk() {
                return dataProvider.delete(resource, { id: record.id })
                    .then(response => {
                        notify(`${title} deleted`)
                        redirect(`/${resource}`)
                    }).catch(error => {
                        notify("Something went wrong", "warning")
                    })
            },
        });
    };
    return <Button style={style} danger onClick={handleClick}>Delete</Button>;
};


export default DeleteButton;
