import { Alert, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import Editor from '@monaco-editor/react';
import yaml from 'js-yaml';
import { editor } from 'monaco-editor';

const AppMetadataModal = ({ modalVisible, setModalVisible, handleModalSubmit, appName, metadata }: { modalVisible: boolean, setModalVisible: Function, handleModalSubmit: (appName: string, metadata: object) => void, appName: string, metadata: any }) => {

    const [isValidMetadata, setIsValidMetaData] = useState<boolean>(true)
    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const parsedMetadata = typeof metadata === 'object'  && Object.keys(metadata).length === 0 ? undefined : metadata

    const handleOk = () => {
        try {
            const appMetadata = editorRef?.current?.getValue() ?? ''
            // If the parsed result is an empty string, convert it to an empty object
            const parsedData = appMetadata.trim() === '' ? {} : yaml.load(appMetadata) as object || {};
            handleModalSubmit(appName, parsedData);
            setIsValidMetaData(true)
            setModalVisible(false)
        } catch (e) {
            console.error(e)
            setIsValidMetaData(false)
        }
    }

    const handleCancel = () => {
        setModalVisible(false)
    }

    const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor) => {
        editorRef.current = editor;
    }

    return (
        <>
            <Modal
                title={`${appName} Metadata`}
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {!isValidMetadata && <Alert message="YAML is invalid" type="error" />}
                <Editor
                    options={{
                        formatOnPaste: true,
                        minimap: {
                            enabled: false,
                        },
                    }}
                    height="50vh"
                    defaultLanguage="yaml"
                    value={(yaml.dump(parsedMetadata))}
                    onMount={handleEditorDidMount}
                />
            </Modal>
        </>
    )
}

export default AppMetadataModal;